<template>
  <div class="mt-11">
    <div class="max-w-2xl mx-auto py-16 sm:py-14 sm:px-6 lg:max-w-7xl lg:px-0" v-if="product">
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-4 lg:items-start">
        <!-- Image gallery -->
        <TabGroup as="div" class="lg:h-800 p-4 bg-white rounded-md">

<!--          <TabPanels class="w-full aspect-w-2 aspect-h-1">-->
<!--            <TabPanel v-for="image in product.pictures" :key="image.id">-->
<!--              <img :src="image?.image" class="w-full h-full object-center object-cover sm:rounded-lg" />-->
<!--            </TabPanel>-->
<!--          </TabPanels>-->


          <div class="lg:mb-6 mt-2 rounded-xl relative">
            <div class="absolute left-0 top-28 w-full z-20 hidden md:flex items-center justify-between">
              <button @click="goToPrevSlide" :class="`h-9 w-9 rounded-full shadow border border-gray-200 bg-white text-gray-700 transform -translate-x-5 bg-opacity-50 group hover:bg-opacity-75 transition duration-200 ease-in-out`">
                <font-awesome-icon icon="fa-angle-left" :class="`group-hover:text-base-600 font-bold`" />
              </button>
              <button @click="goToNextSlide" :class="`h-9 w-9 rounded-full shadow border border-gray-200 bg-white text-gray-700 transform translate-x-5 bg-opacity-50 group hover:bg-opacity-75 transition duration-200 ease-in-out`">
                <font-awesome-icon icon="fa-angle-right" :class="`group-hover:text-base-600 font-bold`" />
              </button>
            </div>
            <Splide :options="splideOptions" aria-label="My Favorite Images" class="splide" ref="slider">
              <SplideSlide v-for="image in product.pictures" class="w-full aspect-w-2 aspect-h-1 relative">
                <div @click="previewImage(image.image)" class="absolute right-0 ml-auto font-bold cursor-pointer z-50 w-12 h-12 bg-black bg-opacity-20 flex items-center justify-center rounded-bl-lg">
                  <font-awesome-icon icon="fa-solid fa-magnifying-glass-plus" class="text-white" />
                </div>
                <img :src="image?.image" class="w-full h-full object-center object-cover sm:rounded-lg" />
              </SplideSlide>
            </Splide>
          </div>


          <!-- Image selector -->
          <div class="hidden mt-12 w-full max-w-2xl mx-auto sm:block lg:max-w-none h-56">
            <TabList class="grid grid-cols-4 gap-5">
              <Tab v-for="(image, i) in product.pictures" :key="image.id" class="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50" v-slot="{ selected }">
                <span class="absolute inset-0 rounded-md overflow-hidden">
                  <img @click="goToSlide(i)" :src="image.image" alt="" class="w-full h-full object-center object-cover" />
                </span>
                <span :class="[selected ? 'ring-base-500' : 'ring-transparent', 'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none']" aria-hidden="true" />
              </Tab>
            </TabList>
          </div>
        </TabGroup>

        <!-- Product info -->
        <div class="mt-5 p-4 lg:mt-0 bg-white rounded-md lg:h-800 relative">
          <div v-if="product.discount" class="absolute text-white text-lg xl:text-2xl font-medium right-0 top-0 p-2 md:p-3 lg:p-2 xl:p-4 bg-base-600 rounded-bl-2xl rounded-tr-md">-{{product.discount}}%</div>
          <h1 class="text-xl font-extrabold tracking-tight text-gray-800 w-10/12">{{ product.name }}</h1>
          <div class="flex items-center mt-4">
            <img class="w-12 h-12 rounded-full border mr-2" :src="product.provider.picture"/>
            <p class="text-gray-600 font-semibold">{{product.provider.name}}</p>
          </div>

          <p class="mt-3 text-gray-600 text-sm font-medium">
            <span class="font-black">Keywords:&nbsp;</span>
            <span class="italic">{{product.keywords}}</span>
          </p>

          <div class="my-4 rounded-md overflow-hidden select-none mt-5">
<!--            <div class="sm:hidden">-->
<!--              &lt;!&ndash; Use an "onChange" listener to redirect the user to the selected tab URL. &ndash;&gt;-->
<!--              <select id="tabs" name="tabs" class="block w-full focus:ring-base-500 focus:border-base-500 border-gray-300 rounded-md">-->
<!--                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current" @click="select_tab(tab.name)">{{ tab.name }}</option>-->
<!--              </select>-->
<!--            </div>-->
            <div :class="`bg-gray-50 p-1.5 rounded-xl overflow-hidden hidden md:block`">
              <div :class="`relative mx-auto flex items-center justify-between font-medium bg-gray-50 rounded-xl w-full cursor-pointer select-none overflow-hidden`">
                <div :class="`absolute left-0 top-0 transform ${tabs.findIndex((t) => t.current) === 0 ? 'translate-x-0' : ''} ${tabs.findIndex((t) => t.current) === 1 ? 'translate-x-100%' : ''} ${tabs.findIndex((t) => t.current) === 2 ? 'translate-x-200%' : ''} ${tabs.findIndex((t) => t.current) === 3 ? 'translate-x-300%' : ''} ${tabs.findIndex((t) => t.current) === 4 ? 'translate-x-400%' : ''} top-0 bg-base-600 w-1/5 h-8 p-1 rounded-xl animation duration-300 ease-in-out`"></div>
                <div v-for="(tab, i) in tabs" :key="tab.name" @click="select_tab(tab.name)" :class="`${tab.current ? 'text-white' : ''} w-1/5 flex items-center justify-center rounded-lg h-8 animation duration-500 ease-in-out`">
                  <p class="relative capitalize">{{ tab.name }}</p>
                </div>
              </div>
            </div>
<!--            <div class="lg:w-5/12 md:h-10 mt-2 lg:mt-0 space-y-2 md:space-y-0 grid md:hidden grid-cols-2 md:grid-cols-4 items-center gap-1 lg:gap-x-1.5 xl:gap-x-3 overflow-hidden">-->
<!--              <button @click="select_tab(tab.name)" v-for="(tab, i) in tabs" :key="tab.name" :class="`col-span-2 md:col-span-1 h-10 text-sm rounded-md border ${tab.current ? 'bg-base-600 text-white' : 'hover:border hover:border-base-500 hover:bg-gray-50 text-gray-400'} animation duration-200 ease-in-out`">ALL</button>-->
<!--            </div>-->
            <div class="bg-base-600 md:hidden">
              <nav class="flex overflow-x-auto no-scrollbar space-x-4" aria-label="Tabs">
                <div @click="select_tab(tab.name)" v-for="tab in tabs" :key="tab.name" :class="[tab.current ? 'bg-base-500 text-white' : 'text-white', 'px-2 py-2 font-medium text-sm cursor-pointer w-full text-center']" :aria-current="tab.current ? 'page' : undefined">
                  {{ loc[tab.name] }}
                </div>
              </nav>
            </div>
          </div>

          <div :class="`max-h-72 overflow-y-auto w-full`">
            <div v-if="current_tab.key == 'promo_material'">
              <div v-if="product[current_tab.key].length > 0" class="flex flex-wrap space-x-2 h-full">
                <a :href="doc.file" target="_blank" v-for="doc in product[current_tab.key]">
                  <DocumentIcon
                    class="w-10 h-10 text-gray-600 hover:text-base-600 cursor-pointer mx-auto"
                  />
                  <p class="text-sm">{{ doc.title }}</p>
                </a>
              </div>
              <div v-else class="h-40 flex items-center justify-center text-gray-400 text-sm">
                {{ loc.no_attachments}}
              </div>
            </div>
            <div :class="`text-sm overflow-y-scroll p-2 h-full`" v-else v-html="product[current_tab.key]"></div>
          </div>

          <div class="mt-6" v-if="!product.fixed && !product.special_offer && Number(product.price)">
            <div class="flex justify-start text-base-700">
              <div>
                <p v-if="!product.fixed && product.price > 0 && product.discount" class="text-lg font-bold line-through text-yellow-500">{{ product.price_currency }}</p>
                <p  v-if="!product.nfc && !product.fixed && product.price > 0" class="text-xl font-bold">{{ product.price_discount_currency }}</p>
              </div>
              <div class="tooltip">
                <InformationCircleIcon class="w-4 h-4 ml-2 mt-1.5" />
                <span class="tooltiptext text-sm font-bold" v-html="product.note"></span>
              </div>
              <!--              <p class="text-xl font-bold">{{ monetary(product.price * (100 - product.discount) / 100, product.currency ) }}</p>-->
            </div>
            <p class="text-gray-500 text-sm">{{loc.vat}}</p>
          </div>



          <div class="mt-6">


            <div :class="`mt-10 ${$store.state.auth.user.cart.findIndex(e=>e.benefit.id == product.id) > -1 ? 'md:flex items-center justify-between gap-x-4 space-y-2 md:space-y-0' : 'md:flex items-center justify-between gap-x-4'} `" v-if="$store.state.auth.user">
<!--              <div v-if="product.nfc">-->
<!--                <img class="w-20 h-16" src="/in_store.jpg"/>-->
<!--              </div>-->
              <!-- IN STORE -->
              <div v-if="product.nfc" class="w-40 flex items-end gap-x-2 text-base-600 ml-1.5">
                <font-awesome-icon icon="fa-solid fa-mobile-alt" class="text-4xl transform scale-110 text-base-600" />
                <p class="text-lg font-medium">IN-STORE</p>
              </div>
              <!-- INVOICE -->
              <div v-else-if="!Number(product.price)" class="flex items-end gap-x-2 text-base-600 ml-1.5">
                <font-awesome-icon icon="fa-solid fa-file-invoice-dollar" class="text-4xl transform scale-110" />
                <p class="text-lg font-medium">INVOICE</p>
              </div>
<!--              <img v-else-if="!Number(product.price)" class="w-16 h-16" src="/icon3.png"/>-->
              <div :class="`${$store.state.auth.user.cart.length > 0 ? 'md:flex' : 'flex'}  items-center gap-x-2 mt-2 md:mt-0 w-full`">
                <button v-if="!product.nfc && Number(product.price) && !product.fixed && !product.special_offer" @click="$store.dispatch('auth/toggleCart', product.id)"
                        :class="`transition duration-200 ease-in-out ${$store.state.auth.user.cart.findIndex(e=>e.benefit.id == product.id) > -1 ? 'bg-yellow-500 hover:bg-yellow-700' : 'bg-base-600 hover:bg-base-500'} border border-transparent rounded-md py-2 px-8 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500 md:w-1/2 h-10`">
                  {{$store.state.auth.user.cart.findIndex(e=>e.benefit.id == product.id) > -1 ? loc.remove_cart : loc.add_cart}}
                </button>
                <button v-else-if="product.nfc" @click="card = true"
                        :class="`transition duration-200 ease-in-out max-w-md flex-1 ${$store.state.auth.user.cart.findIndex(e=>e.benefit.id == product.id) > -1 ? 'bg-yellow-500 hover:bg-yellow-700' : 'bg-base-600 hover:bg-base-500'} border border-transparent rounded-md py-2 px-8 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500 w-full h-10`">
                  {{ loc.my_card }}
                </button>
                <button v-else-if="!Number(product.price)" @click="$router.push('/reimbursement')"
                        :class="`transition duration-200 ease-in-out max-w-md flex-1 ${$store.state.auth.user.cart.findIndex(e=>e.benefit.id == product.id) > -1 ? 'bg-yellow-500 hover:bg-yellow-700' : 'bg-base-600 hover:bg-base-500'} border border-transparent rounded-md py-2 px-8 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500 w-full h-10`">
                  {{ loc.upload_invoice }}
                </button>

                <div :class="`${$store.state.auth.user.cart.length > 0 ? 'mt-2 md:mt-0' : ''} flex items-center justify-between md:justify-end gap-x-4`">

                  <div v-if="benefitId && !product.nfc && Number(product.price) && !product.fixed && !product.special_offer" :class="`h-10  ${touchedCount ? 'w-44' : 'w-32'} flex items-center justify-end gap-x-2`">
                    <button v-if="touchedCount" @click.stop="changed(count, benefitId)" class="w-10 h-10 border border-base-600 text-base-600 rounded hover:bg-base-600 hover:text-white transition duration-200 ease-in-out">
                      <font-awesome-icon icon="fa-solid fa-check" class="" />
                    </button>
                    <div :class="`flex items-center justify-around gap-x-1 text-base-600 font-medium h-10 w-32 rounded border border-base-600 hover:bg-white ml-auto lg:ml-0`">
                      <button :disabled="loading" class="w-1/3 h-10 rounded-l text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''"  @click="count--; changed(count, benefitId);">-</button>
                      <div class="w-1/3 h-10 flex items-center justify-center bg-transparent">
                        <input type="number" v-model="count" @keydown="touchedCount = true" class="bg-transparent w-full text-center border-none outline-none h-6 p-0 m-0">
                      </div>
                      <!--                  <p class="w-1/3 text-center">{{ product.count }}</p>-->
                      <button :disabled="loading" class="w-1/3 h-10 rounded-r text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''" @click="count++; changed(count, benefitId);">+</button>
                    </div>
                  </div>


                  <!--                <div  v-if="benefitId && !product.nfc && Number(product.price) && !product.fixed && !product.special_offer" class="flex items-center justify-around gap-x-1 text-base-600 font-medium h-10 w-28 rounded border border-base-600 hover:bg-white ml-auto lg:ml-0">-->
                  <!--                  <button :disabled="loading" class="w-1/3 h-10 rounded-l text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''" @click="count&#45;&#45;; changed(count, benefitId);">-</button>-->
                  <!--                  <p class="w-1/3 text-center">{{ productCount }}</p>-->
                  <!--                  <button :disabled="loading" class="w-1/3 h-10 rounded-r text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''" @click="count++; changed(count, benefitId);">+</button>-->
                  <!--                </div>-->

                  <button @click="$store.dispatch('auth/toggleFavorites', product.id)" type="button"
                          class="h-10 px-3 rounded-md flex items-center justify-center text-gray-400 hover:text-gray-500">
                    <font-awesome-icon icon="fa-solid fa-heart" v-if="$store.state.auth.user.favorite.findIndex(e=>e.id == product.id) > -1" class="text-red-600 text-xl  transform hover:scale-125 animation duration-200 ease-in-out" />
                    <font-awesome-icon icon="fa-regular fa-heart" v-else class="text-xl text-base-600 hover:text-red-600 transform hover:scale-125 animation duration-200 ease-in-out" />
<!--                    <HeartFull class="w-6 h-6 text-red-600" v-if="$store.state.auth.user.favorite.findIndex(e=>e.id == product.id) > -1"/>-->
<!--                    <HeartIcon v-else class="h-6 w-6 flex-shrink-0 text-base-600" aria-hidden="true" />-->

                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="divide-y pt-3 mt-3 lg:mt-0 lg:pt-0 xl:pt-2 xl:mt-2 text-gray-600">
            <div class="flex justify-between px-1 py-2">
              <p class="font-bold">{{loc.category}}:</p>
              <p class="text-right">{{ parse_name(product.category[0].name) }}</p>
            </div>
            <div class="flex justify-between px-1 py-2" v-if="product.category.length == 2">
              <p class="font-bold">{{loc.subcategory}}:</p>
              <p class="text-right">{{ parse_name(product.category[1].name) }}</p>
            </div>
            <div class="flex justify-between px-1 py-2">
              <p class="font-bold">{{loc.provider}}:</p>
              <p class="text-right">{{ parse_name(product.provider.name) }}</p>
            </div>
          </div>

          <section aria-labelledby="details-heading" class="mt-12">
            <h2 id="details-heading" class="sr-only">Additional details</h2>

            <div class="divide-y divide-gray-200">
              <Disclosure as="div" v-for="detail in product.details" :key="detail.name" v-slot="{ open }">
                <h3>
                  <DisclosureButton class="group relative w-full py-6 flex justify-between items-center text-left">
                    <span :class="[open ? 'text-base-600' : 'text-gray-900', 'text-sm font-medium']">
                      {{ detail.name }}
                    </span>
                    <span class="ml-6 flex items-center">
                      <PlusSmIcon v-if="!open" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      <MinusSmIcon v-else class="block h-6 w-6 text-base-400 group-hover:text-base-500" aria-hidden="true" />
                    </span>
                  </DisclosureButton>
                </h3>
                <DisclosurePanel as="div" class="pb-6 prose prose-sm">
                  <ul role="list">
                    <li v-for="item in detail.items" :key="item">{{ item }}</li>
                  </ul>
                </DisclosurePanel>
              </Disclosure>
            </div>
          </section>
        </div>
      </div>
    </div>
    <hob-card-modal :is-open="card" size="small" @onClose="card = false">
      <div class="hidden md:flex items-center justify-center h-full md:w-500 mx-auto ">
        <div style="background-image: url('/hob-card.png'); height: 380px; width: 500px; margin: auto; background-size:contain; background-repeat: no-repeat;"
             class="flex flex-col justify-start pt-40 px-10 text-2xl font-bold text-white z-50"
        >
          <p>{{$store.state.auth.user.company.name}}</p>
          <p>{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>
          <p>HoB User ID: {{$store.state.auth.user.id}}</p>
        </div>
      </div>
      <div class="md:hidden flex items-center justify-center h-72 bg-transparent">
        <img src="../assets/images/hob-card.png" class="w-screen transform scale-110" alt="HoB Card">
        <div class="absolute text-white font-bold leading-5 bg-cover transform translate-y-8 -translate-x-12 pl-2">
          <p>{{$store.state.auth.user.company.name}}</p>
          <p>{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>
          <p>HoB User ID: {{$store.state.auth.user.id}}</p>
        </div>
      </div>
    </hob-card-modal>
  </div>
</template>

<script >
import { ref } from 'vue'
import HobCardModal from "@/components/ui/HobCardModal.vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/vue'
import { StarIcon, DocumentIcon, InformationCircleIcon, HeartIcon as HeartFull } from '@heroicons/vue/solid'
import { HeartIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/vue/outline'
import {mapActions, mapGetters, mapMutations} from "vuex";
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"

export default {
  components: {
    HobCardModal,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    StarIcon,
    HeartIcon,
    MinusSmIcon,
    PlusSmIcon,
    DocumentIcon,
    InformationCircleIcon,
    HeartFull,
    Viewer
  },
  data() {
    return {
      product: null,
      loading: false,
      card: false,
      touchedCount: false,
      count: 1,
      tabs: [
          { name: 'details', href: '#', current: true, key:'short_description' },
          { name: 'description', href: '#', current: false, key:'description' },
          { name: 'instructions', href: '#', current: false, key:'instructions' },
          { name: 'limitations', href: '#', current: false, key:'limitations' },
        { name: 'promo', href: '#', current: false, key:'promo_material' },
      ],
      splideOptions: {
        type: 'slide',
        speed: 300,
        autoWidth: true,
        autoHeight: true,
        gap: 20,
        perPage: 1,
        pagination: true,
        arrows: false,
        autoScroll: {
          speed: 1,
        },
        drag: 'free',
        mediaQuery: 'min',
      },
      classes: {
        // Add classes for arrows.
        arrows: 'splide__arrows',
        arrow : 'splide__arrow',
        prev  : 'splide__arrow--prev transform -translate-x-8 -translate-y-3 bg-white shadow hover:bg-base-500',
        next  : 'splide__arrow--next transform translate-x-8 -translate-y-3 bg-white shadow hover:bg-base-500',
      },
    }
  },
  computed: {
     ...mapGetters('auth', {
      user: 'user'
    }),
    current_tab() {
      return this.tabs.find(e=>e.current)
    },
    // productCount() {
    //   const product = this.user.cart.find((item) => item.benefit.id === this.product.id);
    //   if (!product) return 0;
    //   this.count = product.count;
    //   return product.count;
    // },
     benefitId() {
      const product = this.user.cart.find((item) => item.benefit.id === this.product.id);
      if (!product) return 0;
      else return product.id;
    },
  },
  methods: {
    ...mapActions('auth', {
      fetchUser: 'fetchUser'
    }),
    ...mapMutations('auth', {
      resetCartItem: 'resetCartItem'
    }),
    async getProduct(id) {
      let {data} = await this.axios.get(`v2/benefit/${id}/`)
      this.product = data;
    },
    previewImage(imgUrl) {
      const imageUrls = this.product.pictures.filter(picture => picture.image !== imgUrl).map(picture => picture.image);
      const images = [imgUrl, ...imageUrls];
      this.$viewerApi({
        images: images
      })
    },
    parse_name(name) {
      let srb = '';
      let eng = '';
      if (name.includes('/')) {
        eng = name.split('/')[0];
        srb = name.split('/')[1];
        if (this.$store.state.auth.user.language == "SR") {
          return srb;
        } else {
          return eng;
        }
      }
      return name;
    },
    getProductCount() {
      const product = this.user.cart.find((item) => item.benefit.id === this.product.id);
      if (!product) return 0;
      this.count = product.count;
    },
    select_tab(name) {
      this.tabs = this.tabs.map(e=>{
        return {...e, current: e.name == name}
      })
    },
    goToSlide(index) {
      if (this.$refs['slider']) {
        this.$refs['slider'].splide.go(index);
      }
    },
    goToPrevSlide() {
      if (this.$refs['slider']) {
        this.$refs['slider'].splide.go('-1');
      }
    },
    goToNextSlide() {
     if (this.$refs['slider']) {
        this.$refs['slider'].splide.go('+1');
      }
    },
    async changed(count, id) {
      if (+count < 1) {
        this.count = 1;
        return;
      }
      this.loading = true;
      try {
        let {data} = await this.axios.patch(`v2/cart-items/${id}/`, { count: count })
        await this.fetchUser()
        this.resetCartItem(data)
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.touchedCount = false;
      }
    },
  },
  watch: {
    '$route.params.id'(val) {
      this.product = null;
      this.getProduct(val);
    },
  },
  async mounted() {
    await this.getProduct(this.$route.params.id);
    this.getProductCount();
  }
}
</script>

<style scoped>

.base-text a {
  color: #3777e2;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 1px 5px;

  /* Position the tooltip */
  position: absolute;
  top: 22px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

input:focus {
  background-color: transparent;
  color: black;
  border-color: #046c9c;
  outline: none;
  box-shadow: none;
}
</style>